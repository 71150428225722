<template>
  <div>
  <v-row>
    <!-- Par jour -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="success"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-percent</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">
            Consommation <br />Par Jour
          </p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ CurrencyFormatting(analyse.stats.per_day) }} DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Par week -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="success"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-percent</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">
            Consommation <br />Par semaine
          </p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ CurrencyFormatting(analyse.stats.per_week) }} DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Par mois -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="success"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-percent</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">
            Consommation <br />Par mois
          </p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ analyse.stats.per_month }} DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Received Refills -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="gifty"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-currency-usd</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <p class="font-weight-bold text-lg mb-1 mt-2">
                  Solde Reçu par le pos
                </p>
                <p class="font-weight-black text-lg mb-1 title" >
                  {{
                    CurrencyFormatting(
                      analyse.totals_account ? analyse.totals_account.total_received_refills : 0
                    )
                  }}
                  DZD
                </p>
        </v-card-text>

        
      </v-sheet>
    </v-col>

    <!-- total Depense -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="success"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-currency-usd</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">Solde Dépensé</p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ CurrencyFormatting(analyse.totals_account ? analyse.totals_account.total_voucher : 0) }}
            DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Restant -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="red"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-currency-usd</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">Solde Restant</p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ CurrencyFormatting(analyse.totals_account ? analyse.totals_account.all_balance : 0) }}
            DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Commission -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="primary"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-currency-usd</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">
            Total des commissions
          </p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ CurrencyFormatting(analyse.totals_account ? analyse.totals_account.total_commissions : 0) }}
            DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Recover -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="blue"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-currency-usd</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">Solde a recouvrir</p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ CurrencyFormatting(analyse.totals_account ? analyse.totals_account.total_recover : 0) }}
            DZD
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>

    <!-- Client -->
    <v-col cols="12" lg="4" md="6">
      <v-sheet
        :rounded="'lg'"
        class="text-center d-flex align-center"
        style="height: 100%"
      >
        <v-card-title class="align-center text-center">
          <v-avatar
            class="elevation-3 text-center"
            color="success"
            size="55"
            style="margin: auto"
          >
            <v-icon color="white "> mdi-account</v-icon>
          </v-avatar>
        </v-card-title>

        <v-card-text class="align-start align-center mt-4">
          <p class="font-weight-bold text-lg mb-1 mt-2">Nombre de clients</p>
          <p class="font-weight-black text-lg mb-1 title">
            {{ analyse.number_clients }}
          </p>
        </v-card-text>
      </v-sheet>
    </v-col>
  </v-row>

  <v-row>
          <v-col cols="12" lg="6">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="info" size="38">
                  <v-icon color="white"> mdi-ticket-percent </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par voucher (CA)</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <DoughnutChart
                  :labels="analyse.byDayGraph.labelPrices"
                  :data="analyse.graphMontant"
                  cutout="0"
                  :is_money="true"
                />
              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="12" lg="6">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="info" size="38">
                  <v-icon color="white"> mdi-ticket-percent </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par voucher (Quantite)</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <!-- <DoughnutChart
                  :labels="analyse.byDayGraph.labelQuantity"
                  :data="analyse.graphQuantity"
                  cutout="0"
                /> -->
                <div class="chartAreaWrapper">
                <Bar
                  :labels="analyse.byDayGraph.labelQuantity"
                  :data="analyse.graphQuantity"
                  backgroundColor="#feaa01"
                  title="Consimmation par quantité"
                  :style="
                    'float: left; width:' +
                    widthGraph(analyse.byDayGraph.labelQuantity) +
                    'px !important;'
                  "
                /></div>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="8">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-update </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par jour</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <!-- <LineChart
                  :labels="analyse.byDayGraph.days"
                  :data="analyse.byDayGraph.totals"
                /> -->
                <div class="chartAreaWrapper">
                <Bar
                  :labels="analyse.byDayGraph.days"
                  :data="analyse.byDayGraph.totals"
                  title=" Consommation par jour"
                  :is_money="true"
                  :style="
                    'float: left; width:' +
                    widthGraph(analyse.byDayGraph.days) +
                    'px !important;'
                  "
                />
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-percent</v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3"> Vouchers</span>
              </v-card-title>
              <v-card-text class="align-start mt-2">
                <div v-if="analyse.vouchers.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="font-weight-bold text-center">Montant</th>
                          <th class="font-weight-bold text-center">Quantite</th>
                          <th class="font-weight-bold text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in analyse.vouchers" :key="'voucher_'+item.amount">
                          <td class="font-weight-bold text-center text-no-wrap">
                            {{ CurrencyFormatting(item.amount) }} DZD
                          </td>
                          <td class="font-weight-bold text-center text-no-wrap">
                            {{ item.quantity }}
                          </td>
                          <td
                            class="
                              font-weight-bold
                              text-right
                              pink--text
                              darken-2
                              text-no-wrap
                            "
                          >
                            {{ CurrencyFormatting(item.total) }} DZD
                          </td>
                        </tr>
                        <tr style="border-top: 1px solid #333">
                          <td class="font-weight-bold text-right">
                            <strong>Total :</strong>
                          </td>
                          <td class="font-weight-bold text-center">
                            {{ CurrencyFormatting(analyse.total_quantity) }}
                          </td>
                          <td class="font-weight-bold text-right">
                            {{ CurrencyFormatting(analyse.total_total) }} DZD
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par Heure</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <!-- <LineChart
                  :labels="analyse.byDayGraph.days"
                  :data="analyse.byDayGraph.totals"
                /> -->
                <div class="chartAreaWrapper">
                <Bar
                  :labels="analyse.graphHours.hours"
                  :data="analyse.graphHours.values"
                  title="Consommation par heure"
                  :is_money="true"
                  :style="
                    'float: left; width:' +
                    widthGraph(analyse.graphHours.hours) +
                    'px !important;'
                  "
                /></div>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>

      </div>
</template>

<script>

import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import Bar from "@/components/charts/Bar.vue";
export default {
  props: ["analyse"],
  components: { Bar, DoughnutChart}
};
</script>

<style scoped>
.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}
</style>
<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="isLoading">
        <v-col cols="6" lg="3" v-for="item in 8" :key="'cards_1'+item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" lg="6" v-for="item in 4" :key="'cards_2'+item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="12" v-for="item in 2" :key="'cards_3'+item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg mt-2" flat>
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <v-btn
                    text
                    color="grey"
                    class="rounded-lg"
                    @click="$router.go(-1)"
                  >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>

                  <h4>
                    {{ analyse.account ? analyse.account.user.name : ""}}
                  </h4>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <AnalyseTotalsAcount :analyse="analyse" />
        
        

        <v-row v-if="isLoadingRefills">
          <v-col cols="12">
            <v-card flat class="rounded-lg">
              <v-card-text>
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card class="rounded-lg" flat>
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Montants reçus</span
                >
              </v-card-title>
              <v-card-text>
                <div class="d-none d-lg-block">
                  <div class="d-flex justify-space-between mb-2">
                    <div></div>
                    <div class="d-flex justify-space-between"></div>
                  </div>
                  <v-divider />
                </div>
                <div>
                  <div v-if="refills.data.length > 0">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="font-weight-bold text-center">ID</th>
                            <th class="font-weight-bold text-center">Date</th>
                            <th class="font-weight-bold text-center">
                              Montant
                            </th>
                            <th class="font-weight-bold text-center">
                              Service
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in refills.data" :key="'reffils_'+item.id">
                            <td class="font-weight-bold text-center">
                              {{ item.id }}
                            </td>

                            <td class="font-weight-bold text-center">
                              {{ item.created_at }}
                            </td>

                            <td
                              class="
                                font-weight-bold
                                text-center text-no-wrap
                                pink--text
                                darken-2
                              "
                            >
                              {{ CurrencyFormatting(item.amount) }} DZD
                            </td>

                            <td class="font-weight-bold text-center">
                              {{
                                item.service != null ? item.service.name : "-"
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td colspan="2" class="font-weight-bold text-right">
                              Total :
                            </td>
                            <td
                              class="
                                font-weight-bold
                                text-center text-no-wrap
                                pink--text
                                darken-2
                              "
                            >
                              {{
                                CurrencyFormatting(
                                  analyse.totals_account.total_received_refills
                                )
                              }}
                              DZD
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="d-flex mt-4">
                      <span class="mt-2 mr-2">Lignes par page :</span>
                      <v-select
                        filled
                        v-model="filter.size"
                        style="width: 20px"
                        dense
                        :items="[10, 20, 30, 40, 50]"
                        @change="onPageChange"
                      ></v-select>
                      <v-spacer />
                      <v-pagination
                        total-visible="6"
                        v-if="refills.total > 15"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(refills.total / filter.size)"
                        @input="onPageChange"
                      ></v-pagination>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import Bar from "@/components/charts/Bar.vue";
import AnalyseTotalsAcount from "./AnalyseTotalsAcount.vue";
export default {
  components: { AnalyseTotalsAcount },
  data() {
    return {
      id: this.$route.params.id,
      filter: {
        startDate: "",
        endDate: "",
        keyword: "",
        supplier_ids: [],
        size: 10,
        page: 1,
      },
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchAnalysesVouchersAccount", {
        id: this.$route.params.id,
      });
    },
    fetchRefills() {
      this.$store.dispatch("fetchRefillsAccount", {
        id: this.$route.params.id,
        filter: this.filter,
      });
    },
    onPageChange() {
      this.fetchRefills();
    },

    
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    isLoadingRefills() {
      return this.$store.getters.isLoadingRefills;
    },
    analyse() {
      return this.$store.getters.getAnalysesVouchersAccount;
    },

    refills() {
      return this.$store.getters.getAccountRefills;
    },
  },
  mounted() {
    // this.filter.startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    this.fetchData();
    this.fetchRefills();
  },
};
</script>

<style scoped>
.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}
</style>